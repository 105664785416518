import React, {useEffect} from 'react'

export default function CardDetailsSmall({customer, cards, card, setActive, getCustomer}) {

  useEffect(() => {
    customer?.card !== card?.last4 && getCustomer();
  }, [])

  return (
    <div className="details rounded">
      <div>
        <h2>Kortuppgifter</h2>
        {!customer && !cards
          ? <div className="card-loading">
            <div>
              <div className="loading card-loading-1 animate-2"></div>
              <div className="loading card-loading-2 animate-2"></div>
            </div>
            <div className="loading card-loading-3 animate-2"></div>
          </div>
          : <div className="card-details">
            {customer?.card !== "0" ?
              <div>
                <div className="label"><label htmlFor="card-details">Inlagt betalkort</label></div>
                <input type="text" name="card-details" value={`XXXX XXXX XXXX ${customer?.card}`} disabled />
              </div>
              : <div className="empty">
                <div className="label"><label htmlFor="">Inget inlagt betalkort</label></div>
                <input type="text" name="card-details" value={`XXXX XXXX XXXX XXXX`} disabled />
              </div>
            }
            <a href="#!" onClick={() => setActive("carddetails")} className="font-italic c-pointer hover-underline edit">
              {customer?.card !== "0" ? 'Redigera ›' : 'Lägg till kort ›'}
            </a>
          </div>
        }
      </div>
    </div>
  )
}
