import { navigate } from 'gatsby'
import React, {useState, useEffect} from 'react'
import ReactSlider from 'react-slider'
import dayjs from 'dayjs'
import ClipLoader from 'react-spinners/ClipLoader'
import {useStripe} from '@stripe/react-stripe-js'
import { gql, useMutation, useQuery } from '@apollo/client'

export default function Subscription({ getCustomer, customer, cards, setActive, mySubscription }) {
  
  const copyKey = (e) => {
    const input = document.querySelector(".copy-input")
    input.select()
    document.execCommand('copy');
    input.focus();
    e.target.textContent = 'Kopierad!'
  }


  const stripe = useStripe();

  const [payOrderStatus, setPayOrderStatus] = useState('')
  const [payOrder] = useMutation(PAY_ORDER)


  const payPendingOrder = (e) => {
    e.preventDefault();
    setPayOrderStatus('resolving')
    payOrder({ variables: { input: { orderId: customer?.subscription.paymentOrderId } } }).then(res => {
      console.log(res.data.payOrderOnHold);
      if (res.data.payOrderOnHold.paymentIntentSecret !== "") {
        stripe.confirmCardPayment(res.data.payOrderOnHold.paymentIntentSecret).then(res => {
          console.log(res);
          if(res?.paymentIntent?.status === "succeeded") {
            // window.location.replace("/user")
            getCustomer();
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
          }).catch(err => {
            console.log(err);
            setPayOrderStatus(err.message)
          })
      }
      else {
        getCustomer();
        // setPayOrderStatus('succeeded')
      }
      
    }).catch(err => {
      console.log(err);
      setPayOrderStatus(err.message)
    })
  }



  return (
    <div className={`subscription d-flex rounded ${customer?.subscription?.subscriptionType === 'TRIAL' && !mySubscription ? 'trial' : ''}${mySubscription ? 'my-subscription' : ''}`}>
      <div>
        <h2>Mitt Abonnemang</h2>
        <h3>{
        customer &&
        (
          (customer?.subscription?.subscriptionType === 'NONE' || customer?.subscription?.subscriptionType === 'EXPIRED')
            ? "Inget aktivt abonnemang. Köp nya licenser nedan!"
            : (
              customer?.subscription?.paymentOrderId !== 0
              ? (
                payOrderStatus === 'resolving' 
                  ? <>Genomför ditt köp... <span className="spinner"><ClipLoader size={16} color={"#ffffffbb"} /></span></>
                  : (
                    payOrderStatus === ''
                      ? (
                        customer?.subscription?.subscriptionType === 'TRIAL_ON_HOLD'
                          ? 'Din testperiod är över, för att fortsätta använda Pausit behöver du köpa licenser.'
                          : 'En automatisk betalning av ditt abonnemang har misslyckats.'
                      )
                      : 'Köpet gick inte igenom. Kolla dina kortuppgifter och försök igen.'
                  )
              )
              : null
            )
        )
        }</h3>
      </div>
      {!customer
        ? <div className="subscription-loading">
          <div className="loading sub-loading-1 animate"></div>
          <div className="loading sub-loading-2 animate"></div>
        </div>
        : <div className="">
          {customer?.subscription?.subscriptionType !== 'NONE' && customer?.subscription?.subscriptionType !== 'EXPIRED' ?
            <div>
              {/* Abonnemang */}
              {customer?.subscription.paymentOrderId === 0 &&
                <div className="subscription-details d-flex">

                  <ul>
                    <li className="d-flex">
                      <div>
                        <p>Licens</p>
                        {customer?.subscription.subscriptionType !== "TRIAL"
                          ? <span>{customer?.subscription?.license.total} st</span>
                          : <span>Prova gratis 21 dagar</span>
                        }
                      </div>
                    </li>
                    <li className="d-flex">
                      {customer?.subscription.subscriptionType !== "TRIAL" &&
                        <div>
                          <p>Pris</p>
                          <span>{Math.round(parseInt(customer?.subscription?.priceForUser))}kr</span>
                        </div>
                      }
                      <div>
                        <p>Startades</p>
                        <span>{dayjs(customer?.subscription.startDate).get('date').toString()}/{(dayjs(customer?.subscription.startDate).get('month') + 1).toString()}-{dayjs(customer?.subscription.startDate).get('year').toString().substr(-2)}</span>
                      </div>
                    </li>
                  </ul>
                  {customer?.subscription.paymentOrderId !== 0
                    ? <small className="font-italic">Misslyckad betalning</small>
                    : <>
                      {customer?.subscription.subscriptionType !== "TRIAL" ?
                        <small className="font-italic">
                          {customer?.subscription?.subscriptionType === 'PENDING_CANCELLATION'
                            ? `Abonnemang avslutas ${dayjs(customer?.subscription.endDate).get('date').toString()}/${(dayjs(customer?.subscription.endDate).get('month') + 1).toString()}-${dayjs(customer?.subscription.endDate).get('year').toString().substr(-2)}`
                            : `Nästa debitering sker ${dayjs(customer?.subscription.nextPaymentDate).get('date').toString()}/${(dayjs(customer?.subscription.nextPaymentDate).get('month') + 1).toString()}-${dayjs(customer?.subscription.nextPaymentDate).get('year').toString().substr(-2)}`
                          }
                        </small>
                        : <small>{dayjs(customer?.subscription?.trialEndDate).diff(dayjs(new Date()), 'days')} dagar kvar</small>
                      }
                    </>
                  }
                </div>
              }


              {/* Licensnyckel */}
              {!mySubscription && customer?.subscription.paymentOrderId === 0 &&
                <div className="license-key">
                  <div className="d-flex align-items-center">
                    <h2>Licensnyckel</h2>
                  </div>
                  <div className="key">
                    <input readOnly className="copy-input" type="text" value={customer?.subscription?.license.key} />
                    <button className="sm" onClick={(e) => copyKey(e)}>Kopiera</button>
                  </div>
                  <button onClick={() => setActive("sharelicense")} className="share">
                    Dela program & nyckel ›
                  </button>
                </div>
              }

              {customer && customer?.subscription.paymentOrderId !== 0 &&
                <div>
                  <div className="none-subscription d-flex justify-content-between">
                    <span></span>
                    { 
                    customer?.subscription?.subscriptionType === 'TRIAL_ON_HOLD' 
                      ? <button disabled={payOrderStatus === 'resolving'} onClick={() => navigate("/shop/checkout")} className="lg">Se priser</button>
                      : (
                        cards?.creditCards?.length 
                          ? <button disabled={payOrderStatus === 'resolving'} onClick={payPendingOrder} className="lg">Betala nu</button>
                          : <button onClick={() => { customer && setActive("carddetails") }} className="lg">Lägg till betalkort</button>
                      )
                    }
                  </div>
                </div>
              }

            </div>
            :
            <>
              <div className="none-subscription d-flex justify-content-between">
                <span></span>
                <button
                  onClick={
                    () => navigate('NONE' === customer?.subscription?.subscriptionType ? "/shop" : "/shop/checkout" )} className="lg">Se priser</button>
              </div>
            </>
          }
        </div>
      }
    </div>
  )
}

const PAY_ORDER = gql`
  mutation PayOrderOnHold($input: PayOrderOnHoldInput!) {
    payOrderOnHold(input: $input) {
      mutationStatus
      paymentIntentSecret
    }
  }
`
