import React, { useState, useEffect, useRef } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { gql, useMutation } from '@apollo/client'
import ClipLoader from 'react-spinners/ClipLoader'
import { useStripeSource } from "../../hooks/useStripeSource"


export default function PaymentMethods({ defaultCard, cards, refetchCards, cardsLoading }) {

  const stripe = useStripe()
  const { handleStripe } = useStripeSource()
  const elements = useElements()
  const formRef = useRef();

  const [creditCards, setCreditCards] = useState([])
  const [status, setStatus] = useState('')
  const [deleteCreditCard] = useMutation(DELETE_CARD)
  const [createSetupIntent] = useMutation(CREATE_SETUP)

  useEffect(() => {
    setCreditCards(cards?.creditCards)
  }, [cards])

  const [addCreditCard] = useMutation(ADD_CARD, {
    onCompleted: (res) => {
      console.log(res);
      setStatus('')
      const cardElement = elements.getElement(CardElement)
      cardElement.clear();
      refetchCards();
    },
    onError: (err) => {
      console.log(err);
      setStatus('')
    }
  })

  const submitCard = async (e) => {
    e.preventDefault();
    setStatus('resolving')

    try {
      const source = await handleStripe({}, CardElement, setStatus)
      const cardElement = elements.getElement(CardElement)
      await createSetupIntent({
        variables: {
          input: {
            sourceId: source.id
          }
        }
      }).then(res => {
        console.log(res.data.createSetupIntent.clientSecret);
        console.log(res.data.createSetupIntent.status);
        if (res.data.createSetupIntent.status === 'success') {
          refetchCards();
          cardElement.clear();
          setStatus('')
        }
        else {
          stripe.confirmCardSetup(res.data.createSetupIntent.clientSecret).then((result) => {
            if (result.error) {
              console.log(result.error);
              setStatus('error')
              cardElement.clear();
            } else {
              console.log('Autentisering genomförd');
              addCreditCard({ variables: { input: { source: source.id } } })
            }
          })
        }
      }).catch(err => {
        console.log(err.message);
        setStatus('error')
      })
    } catch (error) {
      console.error(error)
    }
  }


  const deleteCard = (index, card) => {
    let newArray = [...creditCards]
    newArray.splice(index, 1)
    setCreditCards(newArray)
    deleteCreditCard({ variables: { input: { id: card.id } } }).then(res => {
      console.log(res);
      refetchCards();
    }).catch(err => console.log(err.message))
  }

  return (
    <div className={`payment-methods ${!defaultCard ? 'empty' : ''}`}>
      <h2>Betalmetoder</h2>
      <div className="cards">

        <div className="top-row d-flex">
          <p>Kort</p>
          <p>Utgångsdatum</p>
        </div>
        <ul className="cards-list">
          {creditCards?.length > 0 ? creditCards.map((card, index) => (
            <li key={card.id} className={`d-flex ${card.id === defaultCard?.id ? 'active' : ''}`}>
              <span><strong>{card.last4}</strong> - {card.brand}</span>
              <span>{card.expires}</span>
              <div className="d-flex">
                {card.id === defaultCard?.id && <button className={`sm ${card.id === defaultCard?.id ? 'active' : ''}`}>Förvalt</button>}
                <button className="sm" onClick={() => deleteCard(index, card)}>Ta bort</button>
              </div>
            </li>
          ))
            : <li className="empty"><span>Hittade inga kortuppgifter</span></li>
          }
        </ul>
        <div className="top-row">
          <p>Lägg till ett kort</p>
        </div>
        <form onSubmit={submitCard} className="add-card-form" ref={formRef}>
          <CardElement options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: '13px'
              }
            }
          }} className="stripe-element" />
          <div>
            <button disabled={status === 'resolving'} type="submit" className="sm">Lägg till betalkort</button>
            {(status === 'resolving' || cardsLoading) ?
              <div className="d-flex">
                <div><small>Lägger till kort...</small></div>
                <div className="spinner">
                  <ClipLoader size={16} color={"#004466bb"} />
                </div>
              </div>
              : ''
            }
            {status === 'error' && <div><small>Kunde inte lägga till betalkort, försök igen.</small></div>}
          </div>
        </form>
      </div>
    </div>
  )
}

const CREATE_SETUP = gql`
  mutation CreateSetupIntent($input: CreateSetupIntentInput!){
    createSetupIntent(input: $input) {
      status
      clientSecret
    }
  }
`

const ADD_CARD = gql`
  mutation AddCreditCard($input: AddCreditCardInput!){
    addCreditCard(input: $input) {
      mutationStatus
    }
  }
`
const DELETE_CARD = gql`
  mutation DeleteCreditCard($input: DeleteCreditCardInput!){
    deleteCreditCard(input: $input) {
      mutationStatus
    }
  }
`
