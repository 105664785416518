import React from 'react'
import '../../css/styles.scss'
import { Router } from "@reach/router"
import PrivateRoute from '../../components/privateRoute'
import UserPage from '../../components/user/UserPage'

export default function User() {
  return (
    <Router>
      <PrivateRoute path="/user" component={UserPage}/>
    </Router>
  )
}
