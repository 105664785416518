import React, { useRef, useState } from 'react'
import Eye from '../../images/icons/eye-solid.svg'
import EyeSlash from '../../images/icons/eye-slash-solid.svg'
import Pen from '../../images/icons/pen.svg'
import ClipLoader from 'react-spinners/ClipLoader'
import { useLoginMutation } from '../../hooks/mutations/useLoginMutation'

export default function ChangePassword({ customer, updateCustomer, setPasswordChange }) {
  const passwordRef = useRef()
  const [passwordEdit, setPasswordEdit] = useState(false);
  const [password, setPassword] = useState("********")
  const [passwordStatus, setPasswordStatus] = useState('')
  const [passwordMessage, setPasswordMessage] = useState('')
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [togglePw, setTogglePw] = useState(false)

  const { loginMutation } = useLoginMutation();

  return (
    <li>
      <form onSubmit={(e) => {
        e.preventDefault();
        if (password !== '' && newPassword !== '' && confirmPassword !== '') {
          if (newPassword === confirmPassword) {
            setPasswordStatus('resolving')
            loginMutation(customer?.email, password)
              .then((res) => {
                if (res.data.loginWithCookies.status === 'SUCCESS') {
                  setPasswordChange(true)
                  updateCustomer({ variables: { input: { id: customer?.id, password: newPassword } } }).then(res => {
                    console.log(res);
                    setPasswordEdit(false);
                    setTogglePw(false);
                    setPassword("********")
                    setConfirmPassword('')
                    setPasswordMessage('En bekräftelse skickas till din e-postadress.')
                    setPasswordStatus('resolved')
                    loginMutation(customer?.email, newPassword).then(() => {setNewPassword('');}).catch(err => console.log(err))
                  }).catch(err => { console.log(err); setPasswordStatus(''); })
                }
              })
              .catch((err) => { console.log(err); setPasswordMessage('Felaktigt lösenord'); setPasswordStatus(''); });
          }
          else {
            setPasswordMessage('Lösenorden stämmer inte överens.')
          }
        }
        else {
          setPasswordEdit(false);
          setTogglePw(false);
          setPassword("********")
        }
      }}>
        <div className={`password ${!passwordEdit ? 'd-flex' : ''}`}>
          <span>Lösenord:</span>

          {passwordEdit ?
            <span className="password-input">
              <div>
                <input ref={passwordRef} onChange={(e) => setPassword(e.target.value)} type={!togglePw ? "password" : "text"} id="passwordEdit" name="passwordEdit" disabled={passwordEdit === false} value={password} placeholder={passwordEdit ? "Nuvarande lösenord" : ""} autoComplete="off" />
                <img onClick={() => setTogglePw(!togglePw)} width={17} className="toggle-pw" src={!togglePw ? Eye : EyeSlash} alt="show" />
              </div>
              <div>
                <input onChange={(e) => setNewPassword(e.target.value)} type={!togglePw ? "password" : "text"} value={newPassword} placeholder={passwordEdit ? "Nytt lösenord" : ""} autoComplete="off" />
              </div>
              <div>
                <input onChange={(e) => setConfirmPassword(e.target.value)} type={!togglePw ? "password" : "text"} value={confirmPassword} placeholder={passwordEdit ? "Bekräfta nytt lösenord" : ""} autoComplete="off" />
              </div>
            </span>
            : <span>
              <input type="password" disabled value={password} />
            </span>
          }

        </div>
        <div className="field-edit" onClick={() => setPasswordEdit(true)}>
          <label htmlFor="passwordEdit" className="c-pointer">
            {passwordEdit && password === ""
              ? <button type="submit" className="edit">
                <img src={Pen} alt="pen" />
                Ångra
              </button>
              : passwordEdit
                ? <button type="submit" className="edit">
                  {passwordStatus === 'resolving' ? <span className="spinner"><ClipLoader size={13} color={"#004466bb"} /></span>
                    : <img src={Pen} alt="pen" />
                  }
                  Spara
                </button>
                :
                <span onClick={() => setPassword("")}>
                  <img src={Pen} alt="pen" />
                  Ändra
                </span>
            }
          </label>
        </div>
      </form>
      <small className={`info pw ${passwordStatus === 'resolved' ? 'success' : ''}`}>{passwordMessage}</small>
    </li>
  )
}

