import React from 'react'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'

export default function MySubscription({ customer, setActive }) {
  const copyKey = (e) => {
    const input = document.querySelector(".copy-input")
    input.select()
    document.execCommand('copy');
    input.focus();
    e.target.textContent = 'Kopierad!'
  }

  return (
    <div className={`my-subscription`}>
      <div className={`subscription d-flex rounded ${customer?.subscription?.subscriptionType === 'TRIAL' ? 'trial' : ''}`}>
        <div>
          <h2>Abonnemang</h2>
        </div>
        {!customer
          ? <div className="subscription-loading">
            <div className="loading sub-loading-1 animate"></div>
            <div className="loading sub-loading-2 animate"></div>
          </div>
          : <div>
            <div className={`${customer?.subscription?.paymentOrderId !== 0 ? '' : ''}`}>
              {/* Abonnemang */}
              <div className={`subscription-details d-flex ${customer?.subscription?.subscriptionType === "NONE" && customer?.card !== 0 ? 'previous' : ''}`}>
                <ul>
                  <li className="d-flex">
                    <div>
                      <p>Licens</p>
                      {customer?.subscription?.subscriptionType !== "TRIAL"
                        ? <span>{customer?.subscription?.license.total} st</span>
                        : <span>Prova gratis 21 dagar</span>
                      }
                    </div>
                  </li>
                  <li className="d-flex">
                    {customer?.subscription.subscriptionType !== "TRIAL" &&
                      <div>
                        <p>Pris</p>
                        <span>{Math.round(parseInt(customer?.subscription?.priceForUser))}kr</span>
                      </div>
                    }
                    <div>
                      <p>Startades</p>
                      <span>{dayjs(customer?.subscription.startDate).get('date').toString()}/{(dayjs(customer?.subscription.startDate).get('month') + 1).toString()}-{dayjs(customer?.subscription.startDate).get('year').toString().substr(-2)}</span>
                    </div>
                  </li>
                </ul>
                {customer?.subscription.paymentOrderId !== 0
                  ? <small className="font-italic">Misslyckad betalning</small>
                  : <>
                    {customer?.subscription.subscriptionType !== "TRIAL" ?
                      <small className="font-italic">
                        {customer?.subscription?.subscriptionType === 'PENDING_CANCELLATION'
                          ? `Abonnemang avslutas ${dayjs(customer?.subscription.endDate).get('date').toString()}/${(dayjs(customer?.subscription.endDate).get('month') + 1).toString()}-${dayjs(customer?.subscription.endDate).get('year').toString().substr(-2)}`
                          : `Nästa debitering sker ${dayjs(customer?.subscription.nextPaymentDate).get('date').toString()}/${(dayjs(customer?.subscription.nextPaymentDate).get('month') + 1).toString()}-${dayjs(customer?.subscription.nextPaymentDate).get('year').toString().substr(-2)}`
                        }
                      </small>
                      : <small>{dayjs(customer?.subscription?.trialEndDate).diff(dayjs(new Date()), 'days')} dagar kvar</small>
                    }
                  </>
                }

              </div>
              {customer?.subscription?.subscriptionType !== "NONE" &&
                customer?.subscription?.subscriptionType !== "TRIAL" &&
                customer?.subscription?.subscriptionType !== "PENDING_CANCELLATION" &&
                customer?.subscription.paymentOrderId === 0 &&
                <div className="subscription-buttons">
                {customer?.card !== 0 && <button className="sm" onClick={() => navigate("/subscription/change")}>Ändra antal licenser</button>}
                  <button className="sm" onClick={() => navigate("/subscription/cancel")}>Avsluta abonnemang</button>
                </div>
              }
              {customer?.subscription?.subscriptionType === "PENDING_CANCELLATION" &&
                <div className="subscription-buttons">
                  <button className="sm" onClick={() => navigate("/subscription/reactivate")}>Återuppta abonnemang</button>
                </div>
              }
            </div>

          </div>
        }
      </div>

      {/* Aktivt abonnemang */}
      {customer?.subscription?.subscriptionType !== "NONE" && customer?.subscription.paymentOrderId === 0 &&
        <div className="license-key">
          <div className="d-flex align-items-center">
            <h2>Licensnyckel</h2>
          </div>
          <div className="key">
            <input readOnly className="copy-input" type="text" value={customer?.subscription?.license.key} />
            <button className="sm" onClick={(e) => copyKey(e)}>Kopiera</button>
          </div>
          <button onClick={() => setActive("sharelicense")} className="share">
            Dela program & nyckel ›
          </button>
        </div>
      }
    </div>
  )
}
