import React, { useState, useEffect } from 'react'
import '../../css/styles.scss'
import { Link } from 'gatsby'
import logo from '../../images/pausit_sv.png'
import download from '../../images/icons/download.svg'
import usersolid from '../../images/icons/usersolid.svg'
import logoutIcon from '../../images/icons/logout.svg'
import home from '../../images/icons/home.svg'
import subscription from '../../images/icons/subscription.svg'
import creditcard from '../../images/icons/creditcard.svg'
import useroutline from '../../images/icons/useroutline.svg'
import Subscription from '../../components/user/Subscription'
import MyAccount from '../../components/user/MyAccount'
import MobileNav from '../../components/MobileNav'
import { useAuth } from '../../hooks/useAuth'
import ClipLoader from 'react-spinners/ClipLoader'
import ShareLicense from './ShareLicense'
import CardDetails from './CardDetails'
import dayjs from 'dayjs'
import { useAppState } from '../context'
import MySubscription from './MySubscription'
import PaymentMethods from './PaymentMethods'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { gql, useLazyQuery } from '@apollo/client'
import { useCustomerQuery } from '../../hooks/queries/useCustomerQuery'
import CardDetailsSmall from './CardDetailsSmall'
import Helmet from 'react-helmet'
import Favicon from '../../images/favicon.png'
import { CookiesProvider } from 'react-cookie';
import CookieBar from '../CookieBar'

const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_KEY,
  { locale: 'sv' }
)

export default function UserPage() {

  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const [active, setActive] = useState("start")
  const { logout, status } = useAuth()
  // const { data: cards, refetch: refetchCards, loading: cardsLoading } = useQuery(CARDS)
  const [getCards, { data: cards, refetch: refetchCards, loading: cardsLoading }] = useLazyQuery(CARDS)
  const { customerData: customer } = useAppState()
  const { getCustomer, data: customerData } = useCustomerQuery();
  const [card, setCard] = useState(cards?.creditCards?.find(card => card.isDefault === true))

  useEffect(() => {
    customerData && getCards();
    customerData && console.log(customerData);
  }, [customerData])
  useEffect(() => {
    setCard(cards?.creditCards?.find(card => card.isDefault === true))
    cards && console.log(cards);
    // setCard(cards?.creditCards?.find(card => card.last4 === customer?.card))
  }, [cards])

  return (
    <Elements stripe={stripePromise}>
      <Helmet htmlAttributes={{
        lang: 'sv',
      }}>
        <meta charSet="utf-8" />
        <title>Pausit | Mina sidor</title>
        <link rel="shortcut icon" href={Favicon}></link>
      </Helmet>
      {/* <CookiesProvider> */}
        <div className="user">
          <div className="mobilenav">
            <button className="hamburger-menu custom" onClick={() => setIsMobileOpen(!isMobileOpen)}>
              <div id="nav-icon4" className={`${isMobileOpen ? 'open' : ''}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
            <MobileNav isMobileOpen={isMobileOpen} />
          </div>

          <div className="container">
            {/* Aside Meny */}
            <div className={`aside-container ${isMobileOpen ? 'mobile-open' : ''}`}>
              <aside>
                <div>
                  <Link to="/user"><img className="logo" src={logo} alt="logo" /></Link>
                  <ul className="account">
                    <li>
                      <img src={usersolid} alt="user" />
                      {!customer
                        ? <span className="loading animate-2"></span>
                        : customer.email
                      }
                    </li>
                    <li className="font-italic">
                      <button className="custom" onClick={() => { logout(); }}>
                        {status === 'resolving'
                          ? <span className="spinner"><ClipLoader size={13} color={"#004466bb"} /></span>
                          : <img src={logoutIcon} alt="user" />
                        }
                        Logga ut
                      </button>
                    </li>
                  </ul>
                  <nav>
                    <ul className="sidenav">
                      <li className={`${active === "start" && "active"}`}>
                        <button onClick={() => { customer && setActive("start"); setIsMobileOpen(false); }} className="custom">
                          <img src={home} alt="user" />
                          Start
                        </button>
                      </li>
                      {!customer || customer?.subscription.subscriptionType !== "NONE" ?
                        <>
                          {customer?.subscription.subscriptionType !== "TRIAL_ON_HOLD" &&
                            <li className={`${active === "subscription" && "active"}`}>
                              <button onClick={() => { customer && setActive("subscription"); setIsMobileOpen(false); }} className="custom">
                                <img src={subscription} alt="subscription" />
                                Abonnemang
                              </button>
                            </li>
                          }
                          {customer?.subscription.subscriptionType !== "TRIAL" && customer?.subscription.subscriptionType !== "TRIAL_ON_HOLD" &&
                            <li className={`${active === "carddetails" && "active"}`}>
                              <button onClick={() => { customer && setActive("carddetails"); setIsMobileOpen(false); }} className="custom">
                                <img src={creditcard} alt="carddetails" />
                                Kortuppgifter
                              </button>
                            </li>
                          }
                        </>
                        : ''
                      }
                      <li className={`${active === "account" && "active"}`}>
                        <button onClick={() => { customer && setActive("account"); setIsMobileOpen(false); }} className="custom">
                          <img src={useroutline} alt="account" />
                          Mitt konto
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="aside-bottom">
                  <div className="download rounded">
                    <h3>Ladda ner programmet</h3>
                    <button onClick={() => { customer?.downloads.length > 0 && (customer?.subscription?.subscriptionType !== "NONE" || customer?.subscription?.subscriptionType !== "EXPIRED") && customer?.subscription?.paymentOrderId === 0 && window.location.replace(`${customer.downloads[0].url}`) }}
                      className={`dl ${customer?.subscription?.subscriptionType === "NONE" || customer?.subscription?.subscriptionType === "EXPIRED" || customer?.subscription?.paymentOrderId !== 0 ? 'white' : ''}`}>
                      För Windows
                      <img src={download} alt="dl-windows" />
                    </button>
                    <button onClick={() => { customer?.downloads.length > 0 && (customer?.subscription?.subscriptionType !== "NONE" || customer?.subscription?.subscriptionType !== "EXPIRED") && customer?.subscription?.paymentOrderId === 0 && window.location.replace(`${customer.downloads[1].url}`) }}
                      className={`dl ${customer?.subscription?.subscriptionType === "NONE" || customer?.subscription?.subscriptionType === "EXPIRED" || customer?.subscription?.paymentOrderId !== 0 ? 'white' : ''}`}>
                      För Mac
                      <img src={download} alt="dl-mac" />
                    </button>
                  </div>
                </div>
              </aside>
            </div>

            {/* Content */}
            <div className="content">
              {active === "sharelicense" &&
                <ShareLicense setActive={(value) => setActive(value)} />
              }

              {active === "start" &&
                <>
                  {/* Abonnemang */}
                  <Subscription getCustomer={getCustomer} customer={customer} cards={cards} setActive={(value) => setActive(value)} />

                  {customer?.subscription.subscriptionType === "NONE" || customer?.subscription.subscriptionType === "TRIAL" || customer?.subscription.subscriptionType === "TRIAL_ON_HOLD"
                    ? ''
                    // Om användaren har ett aktivt abonnemang / kortuppgifter
                    : <>
                      <div className="d-flex details-container">
                        {/* Kortuppgifter */}
                        <CardDetailsSmall customer={customer} cards={cards} card={card} setActive={(v) => setActive(v)} getCustomer={getCustomer} />

                        {/* Mitt konto */}
                        <div className="myaccount rounded">
                          <div>
                            <h2>Mitt konto</h2>
                            {!customer
                              ? <div className="account-loading">
                                <div>
                                  <div className="loading account-loading-1 animate-2"></div>
                                  <div className="loading account-loading-2 animate-2"></div>
                                </div>
                                <div className="loading account-loading-3 animate-2"></div>
                              </div>
                              : <div className="account-details">
                                <div>
                                  {customer.firstName !== '' && customer.lastName !== '' ? <p>{customer.firstName} {customer.lastName}</p> : <p></p>}
                                  <span>{customer?.email}</span>
                                </div>
                                <a href="#!" onClick={() => setActive("account")} className="custom font-italic c-pointer hover-underline edit">
                                  Redigera uppgifter ›
                                </a>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  }

                  {/* Om användaren har provvarianten */}
                  {customer?.subscription?.subscriptionType === 'TRIAL' &&
                    <div className="buy-license rounded">
                      <div>
                        <h2>Gillar du Pausit?</h2>
                        <h2>Köp licens här!</h2>
                        <p>Du har {dayjs(customer?.subscription?.trialEndDate).diff(dayjs(new Date()), 'days')} dagar kvar av testperioden.</p>
                        <Link to="/shop/checkout"><button className="lg">Se priser</button></Link>
                      </div>
                    </div>
                  }
                </>
              }

              {active === "subscription" &&
                <>
                  {customer?.subscription.subscriptionType === "NONE" || customer?.subscription.subscriptionType === "EXPIRED" || customer?.subscription.paymentOrderId !== 0
                    ? <Subscription getCustomer={getCustomer} customer={customer} setActive={(value) => setActive(value)} />
                    : <MySubscription customer={customer} refetchCustomer={getCustomer} setActive={(value) => setActive(value)} />
                  }
                </>
              }

              {active === "carddetails" &&
                <>
                  <Elements stripe={stripePromise}>
                    {/* {card && <CardDetails card={card} setCard={(v) => setCard(v)} customer={customer} cards={cards} refetchCards={refetchCards} refetchCustomer={getCustomer} cardsLoading={cardsLoading} />} */}
                    <CardDetails card={card} setCard={(v) => setCard(v)} customer={customer} cards={cards} refetchCustomer={getCustomer} cardsLoading={cardsLoading} />
                    <PaymentMethods defaultCard={card} customer={customer} cards={cards} refetchCards={refetchCards} refetchCustomer={getCustomer} cardsLoading={cardsLoading} />
                  </Elements>
                </>
              }
              {active === "account" &&
                <MyAccount customer={customer} refetchCustomer={getCustomer} />
              }
            </div>
          </div>
          <div className="mobile-support">
            <a href="https://pausit.se/supportsida/" target="_blank">Support</a>
          </div>
        </div>
        {/* <CookieBar /> */}
      {/* </CookiesProvider> */}
    </Elements>
  )
}

const CARDS = gql`
  query {
    creditCards {
      id
      expires
      brand
      isDefault
      last4
    }
  }
`
// const VERIFY_CUSTOMER = gql`
//   mutation($input: VerifyCustomerStatusInput!) {
//     verifyCustomerStatus(input: $input) {
//       mutationStatus
//     }
//   }
// `

