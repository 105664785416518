import React from 'react'
import Select from 'react-select'
import { gql, useMutation } from '@apollo/client'
import dayjs from 'dayjs'

const DEFAULT_CARD = gql`
  mutation DefaultCreditCard($input: DefaultCreditCardInput!){
    defaultCreditCard(input: $input) {
      mutationStatus
    }
  }
`

export default function CardDetails({ card, setCard, cards, refetchCustomer, customer }) {

  const options = cards?.creditCards?.map(card => ({
    value: card,
    label: <div className="card-dropdown d-flex"><span>{card.brand}</span><span>{card.last4}</span></div>
  }))
  const [defaultCreditCard] = useMutation(DEFAULT_CARD)

  return (
    <div className="card-details-container">
      <h2>{card ? 'Förvalt betalkort' : 'Inget inlagt betalkort'}</h2>
      {card ?
        <div className="d-flex">
          <div className="credit-card-container">
            <div>
              <div><small>Kortnummer</small></div>
              <span>XXXX-XXXX-XXXX-{card?.last4}</span>
            </div>
            <div className="d-flex">
              <div>
                <div><small>MM/ÅR</small></div>
                <span>{card?.expires}</span>
              </div>
              <div>
                <div><small>CVC</small></div>
                <span>XXX</span>
              </div>
            </div>
          </div>

          <div className="card-select position-relative">
            <div className="card d-flex">
              <span>{card?.brand}</span>
              <span>{card?.last4}
              </span>
            </div>

            <Select
              className="react-card-select"
              placeholder="Byt betalkort"
              value={card?.last4}
              isSearchable={false}
              options={options}
              autoFocus={false}
              noOptionsMessage={() => 'Inga sparade kort'}
              onChange={(card) => {
                setCard(card.value)
                if (!card.value.isDefault) {
                  defaultCreditCard({ variables: { input: { id: card.value.id } } }).then(() => { refetchCustomer(); }).catch(err => { console.log(err.message); });
                }
              }}
            />
          </div>
        </div>
        : <div className="cards-empty">
          <p>Obs! För att fortsätta använda Pausit behöver du lägga till ett betalkort.</p>
          {(customer?.subscription.nextPaymentDate !== "" || customer?.subscription.nextPaymentDate !== "0") && <p><i>Nästa debitering sker {dayjs(customer?.subscription.nextPaymentDate).get('date').toString()}/{(dayjs(customer?.subscription.nextPaymentDate).get('month') + 1).toString()}-{dayjs(customer?.subscription.nextPaymentDate).get('year').toString().substr(-2)}</i></p>}
        </div>
      }

    </div>
  )
}
