import { Link } from 'gatsby'
import React from 'react'
import logo from '../images/logo.png'

export default function MobileNav() {
  return (
    <nav className="mobile-nav">
      <Link to="/user"><img src={logo} alt="logo" /></Link>
    </nav>
  )
}
