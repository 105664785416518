import React, { useState } from 'react'
import { useCustomerQuery } from '../../hooks/queries/useCustomerQuery'
import Pen from '../../images/icons/pen.svg'

export default function ChangeEmail({ customer, updateCustomer, refetchCustomer }) {

  const [emailEdit, setEmailEdit] = useState(false);
  const [email, setEmail] = useState(customer?.email)
  const [emailMessage, setEmailMessage] = useState('')
  // const { getCustomer } = useCustomerQuery();

  return (
    <li>
      <form onSubmit={(e) => {
        e.preventDefault();
        if (email !== customer?.email) {
          email !== customer?.email && updateCustomer({ variables: { input: { id: customer?.id, email: email } } }).then(res => {
            console.log(res);
            setEmailMessage('En bekräftelse skickas till din gamla e-postadress.')
            // getCustomer();
            refetchCustomer();
          }).catch(err => { console.log(err); })
        }
        setEmailEdit(false);
      }}>
        <div className="d-flex">
          <span>Epost:</span>
          <input onChange={(e) => setEmail(e.target.value)} type="email" id="emailEdit" name="emailEdit" disabled={emailEdit === false} defaultValue={email} required autoComplete="off" />
        </div>
        <div className="field-edit" onClick={() => setEmailEdit(true)}>
          <label htmlFor="emailEdit" className="c-pointer">
            {emailEdit
              ? <button type="submit" className="edit">
                <img src={Pen} alt="pen" />
                Spara
              </button>
              :
              <span>
                <img src={Pen} alt="pen" />
                Ändra
              </span>
            }
          </label>
        </div>
      </form>
      <small className={`info mail ${emailMessage !== '' ? 'd-block' : ''}`}>{emailMessage}</small>
    </li>
  )
}
