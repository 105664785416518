import React, { useEffect } from 'react'
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import Pen from '../../images/icons/pen.svg'
import ClipLoader from 'react-spinners/ClipLoader'

import ChangePassword from './ChangePassword'
import ChangeEmail from './ChangeEmail'

export default function MyAccount({ customer, refetchCustomer }) {
  const [setName] = useState("")
  const [country] = useState(customer?.country)
  const [firstName, setFirstName] = useState(customer?.firstName)
  const [lastName, setLastName] = useState(customer?.lastName)
  const [address, setAddress] = useState(customer?.streetAddress)
  const [company, setCompany] = useState(customer?.company)
  const [vat, setVat] = useState(customer?.vat)
  const [org, setOrg] = useState(customer?.orgNumber)
  const [nameEdit, setNameEdit] = useState(false);
  const [companyEdit, setCompanyEdit] = useState(false);
  const [addressEdit, setAddressEdit] = useState(false);
  const [companyCheckEdit, setCompanyCheckEdit] = useState(false);
  const [companyCheckStatus, setCompanyCheckStatus] = useState('')

  const [passwordChange, setPasswordChange] = useState(false)

  const [updateVat] = useMutation(UPDATE_VAT)
  const [updateOrg] = useMutation(UPDATE_ORG)
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted(res) {
      console.log(res);
      console.log('completed');
      !passwordChange && refetchCustomer();
    },
    onError(error) {
      console.log(error.message);
    }
  })

  const handleVat = (e) => {
    e.preventDefault();
    setCompanyCheckStatus('resolving')
    updateVat({
      variables: {
        input: {
          vat: vat.replace(/\s/g, '')
        }
      }
    }).then(res => {
      if (res.data.updateVat.mutationStatus === true) {
        setCompanyCheckStatus(true)
        refetchCustomer();
      }
      else {
        setCompanyCheckStatus(false)
      }
    }).catch(() => {
      setCompanyCheckStatus(false)
    })
  }

  const handleOrg = (e) => {
    e.preventDefault();
    if (org?.length === 11) {
      setCompanyCheckStatus('resolving')
      updateOrg({ variables: { input: { orgNumber: org.replace(/-/g, "") } } }).then(res => {
        console.log(res.data);
        if (res.data.updateOrgNumber.mutationStatus === true) {
          setCompanyCheckStatus(true)
          refetchCustomer();
        }
        else {
          setCompanyCheckStatus(false)
        }
      }).catch(() => setCompanyCheckStatus(false))
    }
    else {
      setCompanyCheckStatus(false)
    }
  }

  useEffect(() => {
    if (org.length === 10 || org.length === 11) {
      let hasDash = org.includes("-")
      if (!hasDash) {
        let orgInput = [...org];
        orgInput.splice(6, 0, "-");
        // setCompanyDetails(details => ({ ...details, org: orgInput.join('') }))
        setOrg(orgInput.join(''))
      }
    }
  }, [org])

  return (
    <div id="myAccount">
      <div className="myaccount rounded">
        <h2>Mitt konto</h2>
        <ul>
          {/* Epost */}
          <ChangeEmail customer={customer} updateCustomer={updateCustomer} refetchCustomer={refetchCustomer} />

          {/* Lösenord */}
          <ChangePassword customer={customer} updateCustomer={updateCustomer} setPasswordChange={(v) => setPasswordChange(v)} />

          {/* Namn / Kontaktperson */}
          {customer?.firstName !== "" &&
            <li>
              <form className="name" onSubmit={(e) => {
                console.log('mutation');
                if (firstName.length > 0 && lastName.length > 0) {
                  updateCustomer({ variables: { input: { id: customer?.id, billing: { firstName: firstName, lastName: lastName } } } })
                }
                else {
                  setFirstName(firstName);
                  setLastName(lastName);
                }
                setFirstName(firstName)
                setNameEdit(false)
                e.preventDefault();
              }}>
                <div className="d-flex name">
                  <span>{customer?.company !== "" ? 'Kontaktperson:' : 'Namn:'}</span>
                  {!nameEdit &&
                    <input autoComplete="off" type="text" id="firstName-edit" name="firstName-edit" disabled={nameEdit === false} defaultValue={`${firstName} ${lastName}`}
                      onChange={(e) => setName(e.target.value)} required autoComplete="off"
                    />
                  }
                  <div className="field-edit" onClick={() => setNameEdit(true)} style={{ minHeight: 30 }}>
                    <label htmlFor="name-edit" className="c-pointer">
                      {nameEdit
                        ? <button type="submit" className="edit">
                          <img src={Pen} alt="pen" />
                          Spara
                        </button>
                        :
                        <span style={{ marginTop: -10 }}>
                          <img src={Pen} alt="pen" />
                          Ändra
                        </span>
                      }
                    </label>
                  </div>

                </div>
                {nameEdit &&
                  <>
                    <div>
                      <input autoComplete="off" type="text" id="firstName-edit" name="firstName-edit" disabled={nameEdit === false} defaultValue={firstName}
                        onChange={(e) => setFirstName(e.target.value)} placeholder="Förnamn" required autoComplete="off"
                      />
                    </div>
                    <div>
                      <input autoComplete="off" type="text" id="lastName-edit" name="lastName-edit" disabled={nameEdit === false} defaultValue={lastName}
                        onChange={(e) => setLastName(e.target.value)} placeholder="Efternamn" required autoComplete="off"
                      />
                    </div>
                  </>
                }
              </form>
            </li>
          }

          {/* Företag */}
          {customer?.company !== "" &&
            <li>
              <form onSubmit={(e) => {
                e.preventDefault();
                company !== customer?.company ? updateCustomer({ variables: { input: { id: customer?.id, billing: { company: company } } } }) : setCompany(customer?.company)
                setCompanyEdit(false);
              }}>
                <div className="d-flex">
                  <span>Företag:</span>
                  <input onChange={(e) => setCompany(e.target.value)} type="text" id="companyEdit" name="companyEdit" disabled={companyEdit === false} defaultValue={company} required autoComplete="off" />
                </div>
                <div className="field-edit" onClick={() => setCompanyEdit(true)}>
                  <label htmlFor="companyEdit" className="c-pointer">

                    {companyEdit
                      ? <button type="submit" className="edit">
                        <img src={Pen} alt="pen" />
                        Spara
                      </button>
                      :
                      <span>
                        <img src={Pen} alt="pen" />
                        Ändra
                      </span>
                    }
                  </label>
                </div>
              </form>
            </li>
          }

          {/* Address */}
          {customer?.streetAddress !== "" &&
            <li>
              <form onSubmit={(e) => {
                e.preventDefault();
                address.length > 0 ? updateCustomer({ variables: { input: { id: customer?.id, billing: { address1: address } } } }) : setAddress(customer?.streetAddress)
                setAddressEdit(false)
              }}>
                <div className="d-flex">
                  <span>Adress:</span>
                  <input autoComplete="off" type="text" id="address-edit" name="address-edit" disabled={addressEdit === false} value={address}
                    onChange={(e) => setAddress(e.target.value)} required autoComplete="off"
                  />
                </div>
                <div className="field-edit" onClick={() => setAddressEdit(true)}>
                  <label htmlFor="address-edit" className="c-pointer">
                    {addressEdit
                      ? <button type="submit" className="edit">
                        <img src={Pen} alt="pen" />
                        Spara
                      </button>
                      :
                      <span>
                        <img src={Pen} alt="pen" />
                        Ändra
                      </span>
                    }
                  </label>
                </div>
              </form>
            </li>
          }

          {/* VAT/Org-nr */}
          {customer?.company !== "" &&
            <li>
              <form onSubmit={(e) => {
                e.preventDefault();
                setCompanyCheckEdit(false)
                if (companyCheckStatus !== true) {
                  country === 'SE' ? setOrg(customer?.orgNumber) : setVat(customer?.vat)
                  country !== 'SE' && updateVat({ variables: { input: { vat: customer?.vat } } })
                }
              }}>
                <div className="d-flex">
                  <span>{country === "SE" ? 'Organisationsnr:' : 'VAT-nummer:'}</span>
                  {country === "SE"
                    ? <input onKeyDown={(e) => {
                      if (e.key !== "Backspace" && org.length === 6) {
                        let hasDash = org.includes("-")
                        if (!hasDash) {
                          let orgInput = [...org];
                          orgInput.splice(6, 0, "-");
                          setOrg(orgInput.join(''))
                          // setCompanyDetails(details => ({ ...details, org: orgInput.join('') }))
                        }
                      }
                    }} onBlur={(e) => !companyCheckStatus && handleOrg(e)} onKeyUp={(e) => handleOrg(e)} onChange={(e) => setOrg(e.target.value)} type="text" id="org-edit" name="org-edit" disabled={companyCheckEdit === false} value={org} maxLength={11} />
                    : <input onBlur={(e) => !companyCheckStatus && handleVat(e)} onKeyUp={(e) => handleVat(e)} onChange={(e) => setVat(e.target.value)} type="text" id="vat-edit" name="vat-edit" disabled={companyCheckEdit === false} value={vat} />
                  }
                </div>
                <div className="field-edit" onClick={() => setCompanyCheckEdit(true)}>
                  <label htmlFor={country === "SE" ? "org-edit" : "vat-edit"} className="c-pointer">
                    {companyCheckEdit && companyCheckStatus === true
                      // Submit change
                      ? <button type="submit" className="edit">
                        {companyCheckStatus === 'resolving' ? <span className="spinner"><ClipLoader size={13} color={"#004466bb"} /></span>
                          : <img src={Pen} alt="pen" />
                        }
                        Spara
                      </button>
                      // Regret change
                      : companyCheckEdit
                        ? <button type="submit" className="edit">
                          {companyCheckStatus === 'resolving' ? <span className="spinner"><ClipLoader size={13} color={"#004466bb"} /></span>
                            : <img src={Pen} alt="pen" />
                          }
                          Ångra
                        </button>
                        :
                        <span>
                          <img src={Pen} alt="pen" />
                          Ändra
                        </span>
                    }
                  </label>
                </div>
              </form>
              {(companyCheckStatus === false || companyCheckStatus === 'resolving') && companyCheckEdit
                ? <div className="vat-error"><small>Ogiltigt {country === 'SE' ? 'Org-nr' : 'VAT-nummer'}</small></div>
                : ''
              }
            </li>
          }

          {/* Land */}
          {customer?.country !== "" &&
            <li>
              <form>
                <div className="d-flex">
                  <span>Land:</span>
                  <input type="text" disabled defaultValue={customer?.country} />
                </div>
              </form>
            </li>
          }
        </ul>
      </div>
    </div>
  )
}

const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
          username
          email
          billing {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
      }
    }
  }
`;

const UPDATE_VAT = gql`
  mutation($input: UpdateVatInput!) {
    updateVat(input: $input) {
      mutationStatus
    }
  }
`
const UPDATE_ORG = gql`
  mutation($input: UpdateOrgNumberInput!) {
    updateOrgNumber(input: $input) {
      mutationStatus
    }
  }
`
