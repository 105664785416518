import { useMutation } from '@apollo/client';
import { Link } from 'gatsby'
import gql from 'graphql-tag';
import React from 'react'
import { useState, useEffect } from 'react';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import ClipLoader from 'react-spinners/ClipLoader'
import 'react-multi-email/style.css';

const SHARE_LICENSE = gql`
  mutation($input: ShareLicenseInput!) {
    shareLicense(input: $input) {
      mutationStatus
    }
  }
`
export default function ShareLicense({ setActive }) {  
  const [emails, setEmails] = useState([])
  const [status, setStatus] = useState(false)
  const [shareLicense] = useMutation(SHARE_LICENSE, {
    onCompleted: (res) => {
      console.log('share completed');
      console.log(res);
      setEmails([])
      setStatus(true)
      setTimeout(() => {
        setStatus(false)
      }, 5000);
    },
    onError: (err) => {
      console.log(err.message);
      setStatus(false)
    }
  })

  useEffect(() => {
    console.log(emails);
  }, [emails])

  return (
    <div className="share-license">
      <Link to="/user" onClick={() => setActive("start")}>‹ Tillbaka</Link>
      <div className="share-container text-center">
        <h2>Dela licensnyckel och programmet</h2>
        <div className="share-text">
          <p>Ett mail går ut innehållande nedladdningslänkar till programmet och licensnyckel.</p>
          <p>Ange en eller separera flera e-postadresser med mellanslag.</p>
        </div>
        
        <div className="email-container">
          <span>Skicka till</span>
          <ReactMultiEmail
            emails={emails}
            onChange={(_emails) => setEmails(_emails)}
            validateEmail={email => {
              return isEmail(email); // return boolean
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />

         {status === 'resolving' &&
          <div className="spinner">
            <ClipLoader size={16} color={"#004466bb"} />
          </div>
         }
        </div>
        <button disabled={status === 'resolving'} onClick={() => {
          if(emails.length > 0) {
            setStatus('resolving')
            emails.length > 0 && shareLicense({
              variables: { input: { email: emails.toString() } }
            })
          }
          }}>
          Skicka
        </button>

        {status === true &&
          <div className="message">
            Delning lyckades!
          </div>
        }
      </div>
    </div>
  )
}
